<script setup lang="ts">
defineSlice({
  name: { label: 'Bento CMS Animation', group: 'Bento', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesBentoCmsAnimation.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated')
</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-12 w-full justify-between">
    <div class="hidden lg:flex col-span-0 lg:col-span-4 pr-12">
      <div class="relative flex flex-col space-y-2 w-full bg-neutral-900 px-2 py-2 mt-2 ml-2 border-r-2 border-neutral-800">
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Media</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Title</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in delay-[100ms]" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Copy</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in delay-[300ms]" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Card One</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in delay-[500ms]" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Card Two</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in delay-[700ms]" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div>
          <label class="text-neutral-700 text-2xs uppercase">Card Three</label>
          <div class="flex items-center w-full h-4 text-neutral-100 text-2xs rounded duration-500 transition-all ease-in delay-[900ms]" :class="{ 'bg-neutral-800': !activated, 'from-voix-stroke/50 to-voix-stroke bg-gradient-to-br shadow-md shadow-voix-stroke/40': activated }" />
        </div>
        <div class="absolute " />
      </div>
    </div>
    <div class="lg:col-span-8 -translate-y-[50px] flex flex-col items-start md:w-[500px] h-200px rounded bg-neutral-900 border-2 border-neutral-800 p-8">
      <div class="duration-500 shadow-lg h-[75px] w-full rounded ease-in " :class="{ 'bg-voix-alt3 shadow-voix-alt3/20': activated, 'bg-neutral-800': !activated }" />
      <div class="duration-500 shadow-lg h-[10px] w-1/4 rounded mt-4 ease-in delay-[100ms]" :class="{ 'bg-voix-alt1 shadow-voix-alt1/20': activated, 'bg-neutral-800': !activated }" />
      <div class="duration-500 shadow-lg h-[50px] w-1/2 rounded mt-1 ease-in delay-[300ms]" :class="{ 'bg-voix-alt4 shadow-voix-alt4/20': activated, 'bg-neutral-800': !activated }" />
      <div class="grid grid-cols-3 gap-8 w-full mt-6">
        <div class="w-full h-[50px] bg-gradient-to-b rounded duration-500 ease-in delay-[500ms]" :class="{ ' bg-voix-alt2 shadow-voix-alt2': activated, 'bg-neutral-800': !activated }" />
        <div class="w-full h-[50px] bg-gradient-to-b rounded duration-500 ease-in delay-[700ms]" :class="{ ' bg-voix-alt2 shadow-voix-alt2': activated, 'bg-neutral-800': !activated }" />
        <div class="w-full h-[50px] bg-gradient-to-b rounded duration-500 ease-in delay-[900ms]" :class="{ ' bg-voix-alt2 shadow-voix-alt2': activated, 'bg-neutral-800': !activated }" />
      </div>
    </div>
  </div>
</template>
